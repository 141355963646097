<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio task</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Img modal, triggered with click on task attachment -->
                <Transition name="fade-transition">
                    <div v-show="showImgModal" class="attachment_modal">
                        <div class="customers_container">
                            <img :src="selectedImage" alt="Allegato task" />
                        </div>
                        <div @click="closeAttachmentModal()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <!-- task -->
                <div class="task">
                    <div v-if="task.tasks_title" class="field field_task_title">
                        <div class="title task_title">
                            {{ task.tasks_title ? task.tasks_title : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data inizio</div>
                        <div class="value">
                            {{ task.tasks_start_date ? dateFormat(task.tasks_start_date) : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data scadenza</div>
                        <div class="value">
                            {{ task.tasks_due_date ? dateFormat(task.tasks_due_date) : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data consegna</div>
                        <div class="value" :class="deliveryTask(task.tasks_delivery_date)">
                            {{ task.tasks_delivery_date ? dateFormat(task.tasks_delivery_date) : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Progetto</div>
                        <div class="value">
                            {{ task.projects_name ? task.projects_name : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Priorità</div>
                        <div class="badge" :class="taskPriority(task.tasks_priority)">
                            {{ task.tasks_priority_value ? task.tasks_priority_value : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Stato</div>
                        <div class="badge" :class="taskStatus(task.tasks_status)">
                            {{ task.tasks_status_status ? task.tasks_status_status : "-" }}
                        </div>
                    </div>
                    <div class="field_note">
                        <div class="title">Membri</div>
                        <div class="value">
                            <div>{{ getTaskMembers() }}</div>
                        </div>
                    </div>
                    <div v-if="task.tasks_attachments" class="field_allegati">
                        <div class="title">Allegati</div>
                        <div class="allegati_container">
                            <img
                                v-for="(image, index) in JSON.parse(task.tasks_attachments)"
                                :key="index"
                                :src="setImageUrl(image)"
                                alt=""
                                @click="openImgDetail(image)"
                            />
                        </div>
                    </div>
                    <div class="field_note">
                        <div class="title">Descrizione</div>
                        <div class="value">
                            <span v-html="task.tasks_description"></span>
                        </div>
                    </div>
                </div>

                <!-- Todo item -->
                <div v-if="task.tasks_items && Object.keys(JSON.parse(task.tasks_items)).length != 0" class="task">
                    <div class="field">
                        <div class="title task_title">
                            Lista Todo
                        </div>
                    </div>
                    <div v-if="task.tasks_items">
                        <div v-for="(todo, index) in JSON.parse(task.tasks_items)" :key="index" class="todo_container">
                            <div class="text" :class="[todo.checked === '1' ? 'completed' : '']">{{ todo.value }}</div>
                        </div>
                    </div>
                </div>

                <div v-if="messages.length != 0" class="messages">
                    <div class="title">
                        Messaggi
                    </div>
                    <div class="messages_container">
                        <div
                            v-for="message in messages"
                            :key="message.tasks_messages_id"
                            class="message_content"
                            :class="[message.tasks_messages_member == loggeUserId ? 'user_message' : 'customer_message']"
                        >
                            <div>
                                {{ message.tasks_messages_text }}
                            </div>
                            <div class="date">
                                {{ dateFormatWithMinutes(message.tasks_messages_creation_date) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    //IonSpinner,
    modalController,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";

import { defineComponent, onMounted, ref, computed } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";

import { checkField, dateFormat, dateFormatWithMinutes } from "@/services/utils";
import { openToast } from "@/services/toast";
import interventiAPI from "@/services/interventi";
import tasksAPI from "@/services/tasks";

export default defineComponent({
    name: "taskDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        //IonSpinner,
    },
    setup(props, context) {
        const loggeUserId = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;

        const loading = ref(false);
        const task = ref([]);
        const interventi = ref([]);

        /**
         * ! Get ticekt data
         * ! ONLY IF COMING FROM INTERVENTO DETAIL
         */
        /* async function loadtaskDetail(task_id) {
            try {
                task.value = await tasksAPI.gettaskData(task_id);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la task dei dettagli del task", "toast_danger");
            } finally {
                console.log(task.value);
            }
        }

        if (props.task_id && typeof (props.task_id != "undefined")) {
            loadtaskDetail(props.task_id);
        } else {
            task.value = { ...props.data };
        } */
        task.value = { ...props.data };
        console.log(task.value);

        /**
         * ! Open modal to create new intervento
         */
        /*         async function openInterventoDetail(intervento) {
            const modal = await modalController.create({
                component: InterventoDetail,
                componentProps: {
                    data: intervento,
                },
            });
            return modal.present();
        } */

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Print customer reference
         */
        function contattoCliente(task) {
            const name = task.customers_contacts_name ? `${task.customers_contacts_name}` : "";
            const lastName = task.customers_contacts_last_name ? ` ${task.customers_contacts_last_name} ` : "";
            return `${name}${lastName}`;
        }

        /**
         * ! Print customer phone
         */
        function telefonoCliente(task) {
            if (task.customers_contacts_phone) {
                return task.customers_contacts_phone;
            } else if (task.customers_contacts_mobile_number) {
                return task.customers_contacts_mobile_number;
            } else {
                return "";
            }
        }

        /**
         * ! Limit customers to 35 charactes
         */
        function setShortCustomer(customer) {
            if (!customer) {
                return "-";
            } else {
                const trimmedString = customer.length > 35 ? customer.substring(0, 32) + "..." : customer;
                return trimmedString;
            }
        }

        function setImageUrl(img) {
            //console.log(img);
            if (img) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${img.path_local}`;
            }
        }

        /**
         * Remove html tag from task description
         */
        function parseHtmlEnteties(str) {
            if (str) {
                const strippedString = str.replace(/(<([^>]+)>)/gi, "");
                return strippedString.replace(/&#([0-9]{1,3});/gi, function(match, numStr) {
                    const num = parseInt(numStr, 10); // read num as normal number
                    return String.fromCharCode(num);
                });
            } else {
                return "-";
            }
        }

        //Set correct background for task status
        const taskStatus = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 4) {
                    //task todo
                    className = "badge_open";
                } else if (statusId == 2) {
                    //task in lavorazione
                    className = "badge_working";
                } else if (statusId == 5) {
                    //task attesa risposta
                    className = "badge_waiting_reply";
                } else if (statusId == 4) {
                    //task standby
                    className = "badge_standy";
                } else if (statusId == 1) {
                    //task chiuse
                    className = "badge_task_done";
                } else if (statusId == 3) {
                    //task cancellata
                    className = "badge_canceled";
                }
                return className;
            };
        });

        //Set correct background for task priority
        const taskPriority = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //task aperti
                    className = "badge_low";
                } else if (statusId == 2) {
                    //task in lavorazione
                    className = "badge_medium";
                } else if (statusId == 3) {
                    //task attesa risposta
                    className = "badge_high";
                }
                return className;
            };
        });

        //Set correct background for intervento status
        const statusIntervento = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == "1") {
                    //lavoro completato
                    className = "badge_low";
                } else {
                    //lavoro non completato
                    className = "badge_high";
                }
                return className;
            };
        });

        /**
         * Set correct background for data assegnazione if expired
         */
        const deliveryTask = computed(() => {
            return (scadenza) => {
                let className = "";
                if (scadenza) {
                    const dataScadenza = moment(scadenza).valueOf();
                    const dataAttuale = moment().valueOf();

                    if (dataScadenza < dataAttuale) {
                        className = "expired_task";
                    } else {
                        className = "";
                    }
                    return className;
                } else {
                    return className;
                }
            };
        });

        /**
         * Open fake modal to view selected task attachment
         */
        const selectedImage = ref(null);
        const showImgModal = ref(false);

        function openImgDetail(image) {
            showImgModal.value = true;
            selectedImage.value = `${process.env.VUE_APP_BASE_URL}/uploads/${image.path_local}`;
        }

        function closeAttachmentModal() {
            showImgModal.value = false;
        }

        /**
         * Get task messages
         */
        const loadingMessages = ref(false);
        const messages = ref([]);
        const loadtaskMessages = async () => {
            loadingMessages.value = true;
            try {
                messages.value = await tasksAPI.getMessages(task.value.tasks_id);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la task dei messaggi", "toast_danger");
            } finally {
                loadingMessages.value = false;
            }
        };

        /**
         * Get related interventi
         */
        const loadInterventi = async () => {
            loading.value = true;
            try {
                interventi.value = await interventiAPI.getInterventi(task.value.tasks_id);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la task degli interventi", "toast_danger");
            } finally {
                loading.value = false;
            }
        };

        function getTaskMembers() {
            console.log(task.value.tasks_members);
            console.log(typeof task.value.tasks_members);

            if (task.value.tasks_members && typeof task.value.tasks_members === "object") {
                // Estrai i nomi e uniscili con una virgola
                return Object.values(task.value.tasks_members).join(", ");
            }
            return ""; // Ritorna una stringa vuota se non ci sono membri
        }

        onMounted(() => {
            //loadtaskMessages();
            //loadInterventi();
        });

        return {
            loading,
            taskStatus,
            checkField,
            dateFormat,
            dateFormatWithMinutes,
            task,
            arrowBackOutline,
            closeModal,
            parseHtmlEnteties,
            taskPriority,
            setShortCustomer,
            interventi,
            statusIntervento,
            //openInterventoDetail,
            setImageUrl,
            //Attachment modal
            showImgModal,
            selectedImage,
            openImgDetail,
            closeAttachmentModal,
            contattoCliente,
            telefonoCliente,
            //Messaggi task
            loadingMessages,
            messages,
            loggeUserId,
            deliveryTask,
            getTaskMembers,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new task btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

.task {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.task .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 0.5px solid #7676803d;
}
.task .field_task_title {
    justify-content: center;
    border-bottom: none;
}
.task .address_field {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.task .field_allegati {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    border-bottom: 1px solid #e2e8f0;
}
.task .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 16px;
}

.task .address_field .title {
    margin-bottom: 4px;
}
.field .title,
.field_allegati .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #1d1d1b;
}
.field .task_title {
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    border-bottom: none;
}
.field_allegati .title,
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_allegati .value,
.field_note .value {
    font-weight: 600;
    font-size: 14px;
}

.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge {
    font-size: 10px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge_task_done,
.badge_low {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
    font-weight: bold;
}
.badge_standy,
.badge_high {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-weight: bold;
}
.badge_working,
.badge_medium {
    background-color: rgb(255 237 213);
    color: rgb(249 115 22);
    font-weight: bold;
}
.badge_waiting_reply {
    background-color: rgb(237 233 254);
    color: rgb(139 92 246);
    font-weight: bold;
}
.badge_open {
    background-color: rgb(207 250 254);
    color: rgb(6 182 212);
    font-weight: bold;
}
.badge_canceled {
    background-color: rgb(243 244 246);
    color: rgb(31 41 55);
    font-weight: bold;
}

.nuovo_intervento {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.intervento_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Elenco interventi */
.interventi_container {
    margin-top: 16px;
}
.interventi_container .interventi_label {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #475569;
}
.intervento {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.intervento .date {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.intervento .status {
    font-size: 14px;
}

.allegati_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}
.allegati_container img {
    width: 200px;
    margin-right: 16px;
}

.no_interventi_container {
    width: 100%;
    padding: 16px;
}

.no_interventi {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}

/** Modal selected attachment */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.attachment_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 16px;
}

.mobile_link {
    color: #086fa3;
    text-decoration: none;
}

ion-button {
    --color: #ffffff;
}

/**
* Messaggi task
*/
.messages {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.messages .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}

.messages > .title {
    font-size: 16px;
    font-weight: 500;
    color: #475569;
}

.messages .messages_container {
    display: flex;
    flex-direction: column;
    margin: 8px;
}

.messages .messages_container .message_content {
    width: 90%;
    margin-bottom: 12px;
    font-size: 14px;
    padding: 8px;
    border-radius: 4px;
}

.messages .messages_container .message_content.user_message {
    align-self: end;
    background-color: #086fa3;
    color: #ffffff;
}
.messages .messages_container .message_content.customer_message {
    align-self: start;
    background-color: #f1f5f9;
    color: #475569;
}
.messages .messages_container .message_content .date {
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
    margin-top: 8px;
}
.messages .messages_container .message_content.user_message .date {
    color: #ffffff;
}
.messages .messages_container .message_content.customer_message .date {
    color: #475569;
}

/* Todo List */
.todo_container {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #e2e8f0;
    /*margin-bottom: 8px;*/
    padding: 6px 0;
    font-size: 14px;
}
.todo_container:last-of-type {
    border-bottom: none;
}
.todo_container .text {
    width: 100%;
}
.todo_container .text.completed {
    text-decoration: line-through rgba(17, 17, 17, 0.65) 2px;
}
.expired_task {
    color: #dc2626;
    font-weight: 500;
}
.not_epixred {
    color: #6b7280;
}
</style>
